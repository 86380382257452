<template>
  <div>
    <AppModal
      v-model="model"
      :title="$t('ComponentCardCVVInputModal.TitleModal').value"
      @show="focus"
    >
      <template #header="{ dismiss }">
        <AppModalHeader>
          <h1 class="capitalize">{{ title }} {{ maskedAccountNumber }}</h1>
          <template #right>
            <AppBackButton analytics-name="card-cvv-modal-back" icon="x" @click="dismiss" />
          </template>
        </AppModalHeader>
      </template>

      <form id="cvvForm" @submit.prevent="submitSecurityCode">
        <AppInputMask
          v-if="!isAdyen"
          ref="cvvInputMaskRef"
          v-model="validation.securityCode.$value"
          analytics-name="card-cvv-modal-input"
          :label="$t('ComponentCardCVVInputModal.LabelSecurityCode').value"
          :placeholder="$t('ComponentCardCVVInputModal.PlaceholderSecurityCode').value"
          autocomplete="off"
          mask="###"
          :helper-text="$t('ComponentCardCVVInputModal.SecurityCodeText').value"
          :validation="validation.securityCode"
          inputmode="numeric"
        >
          <template #rightIcon>
            <AppButton
              theme="icon"
              class="card-cvv-button"
              analytics-name="card-cvv-modal-card-icon"
              @click="onCVVModal"
            >
              <img :src="require('@galileo/assets/images/cards/pseudo-icon.svg')" alt="Card icon" />
            </AppButton>
          </template>
        </AppInputMask>

        <div v-else class="adyen-cvv-wrapper">
          <label>
            {{ $t('ComponentCardCVVInputModal.LabelSecurityCode').value }}
          </label>
          <span data-cse="encryptedSecurityCode" class="adyen-cvv"></span>
          <span class="adyen-cvv-info">
            {{ $t('ComponentCardCVVInputModal.SecurityCodeText').value }}
          </span>
          <AppButton
            v-if="validation.securityCode.$anyInvalid || !cvvValid"
            theme="icon"
            class="card-cvv-button"
            analytics-name="card-cvv-modal-card-icon"
            @click="onCVVModal"
          >
            <img :src="require('@galileo/assets/images/cards/pseudo-icon.svg')" alt="Card icon" />
          </AppButton>
          <AppIcon v-else class="checkmark-icon">
            <IconCheckmark />
          </AppIcon>
        </div>
        <AppAlert theme="blue" class="card-warning">
          {{ $t('ComponentCardCVVInputModal.AddressWarningText').value }}
        </AppAlert>
      </form>

      <template #footer>
        <AppModalFooter>
          <AppButton
            type="submit"
            form="cvvForm"
            class="submit-btn"
            analytics-name="card-cvv-modal-btn-continue"
            :disabled="validation.securityCode.$anyInvalid || !cvvValid"
            :loading="loading"
          >
            {{ $t('ComponentCardCVVInputModal.ButtonContinue').value }}
          </AppButton>
        </AppModalFooter>
      </template>
    </AppModal>
    <!-- CVV Helper modal  -->
    <CardCVVModal v-model="isCVVModalOpen" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useValidation } from 'vue-composable'
import {
  useAnalyticsStore,
  useCardAccountStore,
  useI18nStore,
  useSendMoneyStore,
} from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'
import CardCVVModal from '@galileo/components/Views/PaymentMethod/CardCVVModal.vue'
import {
  useComponentRefs,
  useVModel,
  AppButton,
  AppInputMask,
  AppModal,
  AppModalFooter,
  AppModalHeader,
  AppBackButton,
  AppIcon,
  AppAlert,
} from '@oen.web.vue2/ui'
import { IconCheckmark } from '@oen.web.vue2/icons'
import useAdyen from '@galileo/composables/useAdyen'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'

export default {
  name: 'CardCVVInputModal',
  components: {
    AppButton,
    AppInputMask,
    AppModal,
    AppModalFooter,
    AppModalHeader,
    AppBackButton,
    CardCVVModal,
    AppIcon,
    IconCheckmark,
    AppAlert,
  },
  emits: ['input', 'submit'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    maskedAccountNumber: {
      type: String,
      default: '',
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    paymentStep: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const analyticsStore = useAnalyticsStore()
    const sendMoneyStore = useSendMoneyStore()
    let adyenComposable = null

    const isAdyen = useCardAccountStore().useAdyen
    const isCVVModalOpen = ref(false)
    const valueRequired = getValueRequiredValidation()
    const onCVVModal = () => {
      isCVVModalOpen.value = true
    }
    const cvvValid = ref(false)

    const validation = useValidation({
      securityCode: {
        $value: ref(''),
        valueRequired,
        minLength: {
          $validator(v) {
            const isValid = !(v.length < 3 || v.length > 3)
            if (isValid) {
              cvvValid.value = true
            }
            return isValid
          },
          $message: $t('PageSendMoneyPayment.ValidationSecurityCodeLength').value,
        },
      },
    })

    const onCvvEntered = (field) => {
      if (field.fieldType === 'encryptedSecurityCode') {
        cvvValid.value = field.valid
        //we fake input in order to pass the validation
        validation.securityCode.$value = '000'
      }
    }

    let adyenCustomCard = null

    const submitSecurityCode = () => {
      if (isAdyen) {
        adyenCustomCard.submit()
        validation.securityCode.$value =
          adyenComposable.adyenData.value.paymentMethod.encryptedSecurityCode
      }
      emit('submit', validation.securityCode.$value)
    }

    onMounted(async () => {
      if (isAdyen) {
        adyenComposable = useAdyen()
        await adyenComposable.init()
        adyenComposable.state.onSuccessCallback = onCvvEntered
        adyenCustomCard = await adyenComposable.mountCVVField('#cvvForm')
      }
    })

    // Returns the current vue component refs
    const { componentRefs } = useComponentRefs()
    // Focuses
    const focus = () => {
      const { value } = componentRefs
      if (value) {
        value.cvvInputMaskRef?.focus()
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.CVV_PROMPTED,
        traits: {
          paymentType: sendMoneyStore.form.paymentMethod,
          location: SEGMENT_LOCATIONS.SEND_MONEY,
        },
      })
    }

    return {
      $t,
      submitSecurityCode,
      isCVVModalOpen,
      onCVVModal,
      model,
      validation,
      focus,
      isAdyen,
      cvvValid,
    }
  },
}
</script>
<style scoped>
::v-deep {
  .button,
  .button.button--icon .button-inner {
    @apply w-auto h-auto;
    @apply block;
    svg {
      @apply block;
    }
  }
  .card-content {
    @apply px-4;

    @screen sm {
      @apply px-12;
    }
  }
  .list-item {
    @apply border-none;
  }

  .list-item-content {
    @apply py-3;
  }
}

.card-warning {
  @apply py-2 px-3 mt-6 leading-5 text-sm rounded font-normal;
  color: theme('colors.blue.default');
}

::v-deep .card {
  .card-header {
    .card-header-title {
      @apply flex items-center;
    }
    .capitalize {
      @apply leading-6;
      font-size: 18px;
    }
  }

  .card-content {
    .card-content-block {
      @apply mb-6 mt-4;

      .card-cvv-button {
        ::v-deep .button-inner {
          border-radius: 0px;
        }
      }
      #encryptedSecurityCode {
        display: block;
        border: solid black;
        height: fit-content;
        max-height: 30px;
      }

      .adyen-cvv-wrapper {
        @apply relative;

        iframe {
          max-height: 46px;
        }

        .adyen-cvv {
          @apply block mb-1 shadow-ria-1;
          font-size: 14px;
          padding-left: var(--theme-input-input-padding-left, 0.75rem);
          border-radius: var(--theme-input-input-border-radius, 0.375rem);
          border: 1px solid theme('colors.gray.light');
        }

        label {
          @apply mb-1 leading-6;
          color: #333;
          font-size: 16px;
          font-weight: 500;
        }

        .adyen-cvv-info {
          @apply block leading-4 text-tertiary-text;
          font-size: 12px;
        }

        .card-cvv-button {
          @apply absolute;
          right: 14px;
          top: 36px;
        }

        .checkmark-icon {
          position: absolute;
          right: 12px;
          top: 38px;
          color: #07893c !important;
        }
      }
    }
  }

  .card-footer {
    .submit-btn {
      @apply w-full;

      &.button--icon .button-inner {
        @apply w-auto h-auto;
        @apply block;
        svg {
          @apply block;
        }
      }
    }
  }
}
</style>
